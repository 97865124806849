import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from '../application-record';
import { Discussion } from '../discussion/model';
import { ObjectPool } from '../object-pool';

export class DailyLog extends ApplicationRecord {
  static prefix = 'dailyLogs' as const;

  date: string;
  notes: string;
  weatherNotes: string;
  createdAt: string;
  updatedAt: string;
  photoAlbumId?: string | null | undefined;

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.date = this.attribute('date', z.string().date());
    this.notes = this.attribute('notes', z.string());
    this.weatherNotes = this.attribute('weatherNotes', z.string());
    this.createdAt = this.attribute(
      'createdAt',
      z.string().datetime({ offset: true })
    );
    this.updatedAt = this.attribute(
      'updatedAt',
      z.string().datetime({ offset: true })
    );
    this.photoAlbumId = this.attribute('photoAlbumId', z.string().nullish());
  }

  get personnelEntries() {
    return this.hasMany(DailyLogPersonnelEntry, 'dailyLogId');
  }

  get equipmentEntries() {
    return this.hasMany(DailyLogEquipmentEntry, 'dailyLogId');
  }

  get discussion() {
    return this.hasOne(Discussion, 'discussableId');
  }

  get photos() {
    return this.discussion?.photos ?? [];
  }
}

export class DailyLogPersonnelEntry extends ApplicationRecord {
  static prefix = 'dailyLogPersonnelEntries' as const;

  dailyLogId: string;
  workerCount: number;
  hoursWorked: number;
  companyId: string;
  notes: string;
  createdAt: string;
  updatedAt: string;

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.dailyLogId = this.attribute('dailyLogId', z.string());
    this.workerCount = this.attribute('workerCount', z.number());
    this.hoursWorked = this.attribute('hoursWorked', z.coerce.number());
    this.companyId = this.attribute('companyId', z.string());
    this.notes = this.attribute('notes', z.string());
    this.createdAt = this.attribute(
      'createdAt',
      z.string().datetime({ offset: true })
    );
    this.updatedAt = this.attribute(
      'updatedAt',
      z.string().datetime({ offset: true })
    );
  }

  get dailyLog() {
    return this.belongsTo(DailyLog, this.dailyLogId);
  }
}

export class DailyLogEquipmentEntry extends ApplicationRecord {
  static prefix = 'dailyLogEquipmentEntries' as const;
  dailyLogId: string;
  name: string;
  inspectedAt?: string | null;
  notes: string;
  createdAt: string;
  updatedAt: string;

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.dailyLogId = this.attribute('dailyLogId', z.string());
    this.name = this.attribute('name', z.string());
    this.inspectedAt = this.attribute('inspectedAt', z.string().nullish());
    this.notes = this.attribute('notes', z.string());
    this.createdAt = this.attribute(
      'createdAt',
      z.string().datetime({ offset: true })
    );
    this.updatedAt = this.attribute(
      'updatedAt',
      z.string().datetime({ offset: true })
    );
  }

  get dailyLog() {
    return this.belongsTo(DailyLog, this.dailyLogId);
  }
}
