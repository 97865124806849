import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from '../application-record';
import { CommentThread } from '../comment-thread/model';
import { ObjectPool } from '../object-pool';
import { User } from '../user/model';
import { ScreenRecordingInfo, screenRecordingSchema } from './schema';

const mediaAttachmentSchema = z.object({
  id: z.string().nullish(),
  name: z.string(),
  originalUrl: z.string(),
  largeUrl: z.string().nullish(),
  thumbnailUrl: z.string().nullish(),
});
type MediaAttachment = z.infer<typeof mediaAttachmentSchema>;

export class Comment extends ApplicationRecord {
  static prefix = 'comments' as const;

  content: string;
  commentThreadId: string;
  commenterId: string;
  mediaAttachments?: MediaAttachment[] | null;
  screenRecordingInfo?: ScreenRecordingInfo | null;
  createdAt: string;
  updatedAt: string;

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.content = this.attribute('content', z.string());
    this.commentThreadId = this.attribute('commentThreadId', z.string());
    this.commenterId = this.attribute('commenterId', z.string());
    this.mediaAttachments = this.attribute(
      'mediaAttachments',
      z.array(mediaAttachmentSchema).nullish()
    );
    this.screenRecordingInfo = this.attribute(
      'screenRecordingInfo',
      screenRecordingSchema.nullish()
    );
    this.createdAt = this.attribute(
      'createdAt',
      z.string().datetime({ offset: true })
    );
    this.updatedAt = this.attribute(
      'updatedAt',
      z.string().datetime({ offset: true })
    );
  }

  get commentThread() {
    return this.belongsTo(CommentThread, this.commentThreadId);
  }

  get commenter() {
    return this.belongsTo(User, this.commenterId);
  }
}
