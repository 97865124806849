import { ReadonlyJSONObject } from '@rocicorp/rails';
import { z } from 'zod';
import { ApplicationRecord } from '../application-record';
import { DrawingSetPage } from '../drawing-set-page';
import { ObjectPool } from '../object-pool';
import { Organization } from '../organization/model';

export class SheetNumberLink extends ApplicationRecord {
  organizationId: string;
  sourcePageId: string;
  sheetNumber: string;
  xMin: number;
  yMin: number;
  xMax: number;
  yMax: number;

  static prefix = 'sheetNumberLinks' as const;

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.organizationId = this.attribute('organizationId', z.string());
    this.sourcePageId = this.attribute('sourcePageId', z.string());
    this.sheetNumber = this.attribute('sheetNumber', z.string());
    this.xMin = this.attribute('xMin', z.coerce.number());
    this.yMin = this.attribute('yMin', z.coerce.number());
    this.xMax = this.attribute('xMax', z.coerce.number());
    this.yMax = this.attribute('yMax', z.coerce.number());
  }

  get organization() {
    return this.belongsToOptional(Organization, this.organizationId);
  }

  get sourcePage() {
    return this.belongsToOptional(DrawingSetPage, this.sourcePageId);
  }
}
