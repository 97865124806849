import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from '../application-record';
import { ObjectPool } from '../object-pool';
import { Photo } from '../photo';

export class Discussion extends ApplicationRecord {
  static prefix = 'discussions' as const;

  discussableType: string;
  discussableId: string;
  createdAt: string;
  updatedAt: string;

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.discussableType = this.attribute('discussableType', z.string());
    this.discussableId = this.attribute('discussableId', z.string());
    this.createdAt = this.attribute(
      'createdAt',
      z.string().datetime({ offset: true })
    );
    this.updatedAt = this.attribute(
      'updatedAt',
      z.string().datetime({ offset: true })
    );
  }

  get photos() {
    return this.hasMany(Photo, 'discussionId');
  }
}
